import { useQuery, useMutation } from "@swan-io/graphql-client";
import { Box } from "fleming-lake/src/components/Box";
import { Icon, IconName } from "fleming-lake/src/components/Icon";
import { LakeAlert } from "fleming-lake/src/components/LakeAlert";
import { LakeCopyButton } from "fleming-lake/src/components/LakeCopyButton";
import { LakeHeading } from "fleming-lake/src/components/LakeHeading";
import { LakeLabel } from "fleming-lake/src/components/LakeLabel";
import { LakeText } from "fleming-lake/src/components/LakeText";
import { Link } from "fleming-lake/src/components/Link";
import { ListRightPanelContent } from "fleming-lake/src/components/ListRightPanel";
import { LoadingView } from "fleming-lake/src/components/LoadingView";
import { ReadOnlyFieldList } from "fleming-lake/src/components/ReadOnlyFieldList";
import { Separator } from "fleming-lake/src/components/Separator";
import { Space } from "fleming-lake/src/components/Space";
import { useIsSuspendable } from "fleming-lake/src/components/Suspendable";
import { Tag } from "fleming-lake/src/components/Tag";
import { Tile } from "fleming-lake/src/components/Tile";
import { commonStyles } from "fleming-lake/src/constants/commonStyles";
import { breakpoints, colors } from "fleming-lake/src/constants/design";
import {
  isNotEmpty,
  isNotNullish,
  isNotNullishOrEmpty,
  isNullish,
} from "fleming-lake/src/utils/nullish";
import { printIbanFormat } from "fleming-shared-business/src/utils/validation";
import { ScrollView, StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { TransactionDetailsFragment, TransactionDocument, CategoryDecorator, FlemingAddTransactionCategoryDocument, TransactionSide } from "../graphql/partner";
import { formatCurrency, formatDateTime, t, getKeysByPrefix, TranslationKey } from "../utils/i18n";
import { Router } from "../utils/routes";
import {
  getFeesDescription,
  getTransactionRejectedReasonLabel,
  getWiseIctLabel,
} from "../utils/templateTranslations";
import { ErrorView } from "./ErrorView";
import { getTransactionLabel } from "./TransactionListCells";
import { useForm } from "@swan-io/use-form";
import { showToast } from "fleming-lake/src/state/toasts";
import { Item, LakeSelect } from "fleming-lake/src/components/LakeSelect";
import { ResponsiveContainer } from "fleming-lake/src/components/ResponsiveContainer";
import { LakeButton, LakeButtonGroup } from "fleming-lake/src/components/LakeButton";
import { DetailLine } from "./DetailLine";

export type Category = { [key: string]: string };
export type Document<Doc extends string> = {
  id: string;
  name?: string;
  downloadUrl?: string;
  document: Doc;
};

const getCategoryItems = (category: 'income' | 'expenses') => {
  const prefix = `categories.${category}.`;
  return getKeysByPrefix(prefix).filter(([key]) => {
    return key.startsWith(prefix);
  }).map(([key]: [key: string, value: string | undefined]) => ({ name: t(key as TranslationKey), value: key})).sort((a, b) => (a.name.localeCompare(b.name)));
};

const NO_CATEGORY_KEY: { expenses: TranslationKey, income: TranslationKey } = {
  expenses: 'categories.expenses.none',
  income: 'categories.income.none'
}

const styles = StyleSheet.create({
  container: {
    ...commonStyles.fill,
  },
  tile: {
    alignItems: "center",
  },
  debitAmount: {
    marginLeft: -16,
  },
  wrapText: {
    wordBreak: "break-all",
  },
});

const formatMaskedPan = (value: string) => value.replace(/X/g, "•").replace(/(.{4})(?!$)/g, "$1 ");
const truncateTransactionId = (id: string) => id.split("#")[0] ?? id;

const Line = ({ icon, label, text }: { icon?: IconName; label: string; text: string }) => (
  <LakeLabel
    type="viewSmall"
    label={label}
    render={() =>
      icon ? (
        <Box direction="row" alignItems="center">
          <Icon name={icon} size={16} />
          <Space width={8} />

          <LakeText variant="regular" color={colors.gray[900]}>
            {text}
          </LakeText>
        </Box>
      ) : (
        <LakeText variant="regular" color={colors.gray[900]}>
          {text}
        </LakeText>
      )
    }
  />
);

const CopiableLine = ({ label, text }: { label: string; text: string }) => (
  <LakeLabel
    type="viewSmall"
    label={label}
    actions={
      <LakeCopyButton
        valueToCopy={text}
        copiedText={t("copyButton.copiedTooltip")}
        copyText={t("copyButton.copyTooltip")}
      />
    }
    render={() => (
      <LakeText variant="regular" color={colors.gray[900]}>
        {text}
      </LakeText>
    )}
  />
);

type Props = {
  accountMembershipId: string;
  userId: string;
  transactionId: string;
  large: boolean;
  transaction?: TransactionDetailsFragment;
  canQueryCardOnTransaction: boolean;
  canViewAccount: boolean;
  transactionUpdating: boolean;
  setTransactionUpdating: (state: boolean) => void;
  accountId: string | undefined;
};

export const getCategoryDecorator = (transaction: TransactionDetailsFragment) => {
  if (transaction.decorators.length === 0) { return null }
  const categoryDecorator: CategoryDecorator | undefined = transaction.decorators.find((d) => (d as CategoryDecorator)?.__typename === 'CategoryDecorator') as CategoryDecorator
  if (categoryDecorator === undefined) { return null }
  return categoryDecorator;
};

export const getInitialCategoryValue = (transaction: TransactionDetailsFragment | undefined):string => {
  if (transaction === undefined) {
    return t(NO_CATEGORY_KEY.expenses);
  }
  const defaultKey = (transaction.side === "Debit") ? NO_CATEGORY_KEY.expenses : NO_CATEGORY_KEY.income
  const categoryDecorator: CategoryDecorator | null = getCategoryDecorator(transaction);
  if (!categoryDecorator) {
    return t(defaultKey)
  }
  const value: string = match(categoryDecorator)
    .with({ __typename: "CategoryDecorator" }, (decorator) => {
      return t(decorator.decorationKey as TranslationKey);
    })
    .otherwise(() => t(defaultKey));
    return value;
}

export const getCategoryKey = (side: TransactionSide, category: string):TranslationKey => {
  const items = getCategoryItems((side === "Debit") ? 'expenses' : 'income');
  const item: { name: string | undefined, value: string } | undefined = items.find((item) => item.value === category);
  const key: TranslationKey = ((item?.value !== undefined) ? item.value : (side === "Debit") ? NO_CATEGORY_KEY.expenses : NO_CATEGORY_KEY.income) as TranslationKey;
  return key;
}


export const TransactionDetail = ({
  transaction: transactionRecord,
  accountMembershipId,
  transactionId,
  large,
  canQueryCardOnTransaction,
  canViewAccount,
  accountId,
  transactionUpdating,
  userId,
  setTransactionUpdating,
}: Props) => {
  const [addCategory] = useMutation(FlemingAddTransactionCategoryDocument,
);

  const { Field } = useForm({
    category: {
      initialValue: getInitialCategoryValue(transactionRecord),
    },
    document: {
      initialValue: [],
    }
  });

  const suspense = useIsSuspendable();
  const [data] = useQuery(
    TransactionDocument,
    {
      id: transactionId,
      canViewAccount,
      canQueryCardOnTransaction,
    },
    { suspense },
  );

  if (data.isNotAsked() || data.isLoading()) {
    return <LoadingView />;
  }

  const result = data.get();

  if (result.isError()) {
    return <ErrorView error={result.getError()} />;
  }

  const transaction = result.get().transaction;

  if (transaction == null) {
    return <ErrorView />;
  }

  const handleCategoryChange = (category: string) => {
    if (isNullish(accountId)) { return; }
    setTransactionUpdating(true)
    const existingCategoryDecorator = getCategoryDecorator(transaction);
    const categoryKey = getCategoryKey(transaction.side, category);
    const input = {
      id: existingCategoryDecorator?.id,
      accountMembershipId,
      categoryKey,
      accountId,
      transactionId: transaction.id,
      userId,
    };

    addCategory({ input })
      .tapOk(() => {
        setTransactionUpdating(false)
      })
      .tapError(_ => {
        showToast({ variant: "error", title: t('error.generic') });
      });
  }

const category = <Field name="category">
  {({ error, ref }) => {
    return (
      <LakeLabel
        type="viewSmall"
        label={t("transaction.category")}
        render={id => (
          <LakeSelect
            placeholder={t("transaction.category")}
            value={getInitialCategoryValue(transaction)}
            items={getCategoryItems((transaction.side === 'Debit') ? 'expenses' : 'income') as Item<string>[]}
            error={error}
            onValueChange={handleCategoryChange}
            ref={ref}
            id={id}
          />
        )}
      />
    )
  }}
</Field>

const repeatTransaction: JSX.Element | null = match(transaction)
.with(
  {
    type: P.union("SepaCreditTransferOut", "SepaInstantCreditTransferOut"),
    creditor: {
      __typename: P.union("SEPACreditTransferOutCreditor"),
      IBAN: P.string,
      name: P.string,
    },
  },
  transaction => {
    const IBAN = transaction.creditor.IBAN;
    const name = transaction.creditor.name;
    const amount = transaction.amount.value;
    const currency = transaction.amount.currency;
    const reference = transaction.reference;
    const label = transaction.label;
    return (
      <ResponsiveContainer breakpoint={breakpoints.large} style={commonStyles.fill}>
        {() => (
          <LakeButtonGroup>
            <LakeButton
              onPress={() =>
                Router.push("AccountPaymentsRepeat", {
                  accountMembershipId,
                  type: "transfer",
                  name,
                  IBAN,
                  amount,
                  currency,
                  label,
                  reference,
                })
              }
              icon="add-circle-filled"
              size="small"
              color="current"
            >
              {t("transfer.repeatTransfer")}
            </LakeButton>
          </LakeButtonGroup>
        )}
      </ResponsiveContainer>
    );
  },
)
.otherwise(() => null);

  return (
    <>{ Boolean(transactionUpdating) ? <LoadingView /> :
    <ScrollView contentContainerStyle={large ? commonStyles.fill : undefined}>
      <ListRightPanelContent large={large} style={styles.container}>
        <Tile
          style={styles.tile}
          footer={match(transaction)
            // BankingFee should never happen, so we don't handle it
            .with({ feesType: P.not("BankingFee") }, ({ feesType }) => {
              const description: string | null = getFeesDescription(feesType) as string | null;

              if (isNullish(description)) {
                return null;
              }

              return <LakeAlert anchored={true} variant="info" title={description} />;
            })
            .with(
              {
                statusInfo: { status: "Pending" },
                originTransaction: {
                  type: P.union("SepaInstantCreditTransferIn", "SepaInstantCreditTransferOut"),
                },
              },
              () => (
                <LakeAlert
                  anchored={true}
                  variant="warning"
                  title={t("transaction.instantTransferUnavailable")}
                  children={t("transaction.instantTransferUnavailable.description")}
                />
              ),
            )
            .with(
              {
                // We display the reason of a rejected transaction which isn't a fee because it has already an alert displayed
                __typename: P.not("FeeTransaction"),
                statusInfo: { __typename: "RejectedTransactionStatusInfo", reason: P.select() },
              },
              reason => {
                const description: string | null = getTransactionRejectedReasonLabel(reason)  as string | null;
                if (isNullish(description)) {
                  return null;
                }
                return <LakeAlert anchored={true} variant="error" title={description} />;
              },
            )
            .with(
              {
                statusInfo: {
                  __typename: "PendingTransactionStatusInfo",
                  pendingEndDate: P.select(P.string),
                },
              },
              pendingEndDate => (
                <LakeAlert
                  anchored={true}
                  variant="warning"
                  title={t("transaction.pendingTransaction.description", {
                    executionDate: formatDateTime(new Date(pendingEndDate), "LL"),
                  })}
                />
              ),
            )
            .otherwise(() => null)}
        >
          {match(transaction.statusInfo.__typename)
            .with("PendingTransactionStatusInfo", () => (
              <Tag color="warning">{t("transactionStatus.pending")}</Tag>
            ))
            .with("RejectedTransactionStatusInfo", () => (
              <Tag color="negative">{t("transactionStatus.rejected")}</Tag>
            ))
            .with("CanceledTransactionStatusInfo", () => (
              <Tag color="gray">{t("transactionStatus.canceled")}</Tag>
            ))
            .otherwise(() => null)}

          <Space height={12} />

          <LakeHeading
            variant="h1"
            level={2}
            align="center"
            style={transaction.side ? styles.debitAmount : null}
          >
            {(transaction.side === "Debit" ? "-" : "+") +
              formatCurrency(Number(transaction.amount.value), transaction.amount.currency)}
          </LakeHeading>

          {match(transaction)
            .with(
              {
                __typename: "CardTransaction",
                originalAmount: P.select("originalAmount", {
                  value: P.select("value", P.string),
                  currency: P.select("currency", P.string),
                }),
              },
              ({ currency, value, originalAmount }) =>
                originalAmount.currency !== transaction.amount.currency ? (
                  <LakeText>
                    {(transaction.side === "Debit" ? "-" : "+") +
                      formatCurrency(Number(value), currency)}
                  </LakeText>
                ) : null,
            )
            .with(
              {
                __typename: "InternationalCreditTransferTransaction",
                internationalCurrencyExchange: P.select("internationalCurrencyExchange", {
                  sourceAmount: { currency: P.string },
                  targetAmount: { value: P.string, currency: P.string },
                }),
              },
              ({ internationalCurrencyExchange }) =>
                internationalCurrencyExchange.sourceAmount.currency !==
                internationalCurrencyExchange.targetAmount.currency ? (
                  <LakeText>
                    {(transaction.side === "Debit" ? "-" : "+") +
                      formatCurrency(
                        Number(internationalCurrencyExchange.targetAmount.value),
                        internationalCurrencyExchange.targetAmount.currency,
                      )}
                  </LakeText>
                ) : null,
            )
            .otherwise(() => null)}

          <Space height={8} />

          <LakeHeading
            variant="h4"
            level={3}
            align="center"
            color={colors.gray[700]}
            style={styles.wrapText}
          >
            {getTransactionLabel(transaction)}
          </LakeHeading>
        </Tile>

        <Space height={24} />

        <ScrollView style={commonStyles.fill} contentContainerStyle={commonStyles.fill}>
          <ReadOnlyFieldList>
            {match(transaction.statusInfo)
              .with({ __typename: "BookedTransactionStatusInfo" }, ({ bookingDate }) => (
                <Line
                  label={t("transaction.bookingDateTime")}
                  text={formatDateTime(new Date(bookingDate), "LLL")}
                  icon="calendar-ltr-regular"
                />
              ))
              .with({ __typename: "UpcomingTransactionStatusInfo" }, ({ executionDate }) => (
                <Line
                  label={t("transaction.executionDateTime")}
                  text={formatDateTime(new Date(executionDate), "LLL")}
                  icon="calendar-ltr-regular"
                />
              ))
              .with(
                {
                  __typename: "CanceledTransactionStatusInfo",
                  canceledDate: P.not(P.nullish),
                },
                ({ canceledDate }) => (
                  <Line
                    label={t("transaction.canceledDate")}
                    text={formatDateTime(new Date(canceledDate), "LLL")}
                    icon="calendar-ltr-regular"
                  />
                ),
              )
              .otherwise(() => null)}
          </ReadOnlyFieldList>

          <Separator space={8} />

          {match(transaction)
            .with(
              { __typename: "CardTransaction" },
              {
                cardDetails: {
                  __typename: "CardOutDetails",
                },
              },
              ({
                cardDetails,
                payment,
                enrichedTransactionInfo,
                statusInfo: { status },
              }) => {
                return (
                  <ReadOnlyFieldList>
                    {isNotNullish(payment) && (status === "Booked" || status === "Pending") && (
                      <DetailLine
                        label={t("transaction.paymentDateTime")}
                        text={formatDateTime(payment.createdAt, "LLL")}
                        icon="calendar-ltr-regular"
                      />
                    )}

                    {isNotNullish(accountId) && category}


                    {cardDetails?.__typename === "CardOutDetails" && (
                          <ReadOnlyFieldList>
                            <DetailLine
                              label={t("transaction.maskedPan")}
                              text={formatMaskedPan(cardDetails.maskedPan)}
                            />

                            {match(cardDetails.card?.accountMembership.user)
                              .with(
                                { firstName: P.string, lastName: P.string },
                                ({ firstName, lastName }) => (
                                  <DetailLine
                                    label={t("transaction.cardHolder")}
                                    text={[firstName, lastName].join(" ")}
                                    icon="person-regular"
                                  />
                                ),
                              )
                              .otherwise(() => null)}
                          </ReadOnlyFieldList>
                        )}

                      <DetailLine
                          label={t("transaction.paymentMethod")}
                          text={t("transactions.method.Card")}
                          icon="payment-regular"
                        />

                      {match(enrichedTransactionInfo)
                          .with({ isSubscription: P.select(P.boolean) }, isSubscription => (
                            <DetailLine
                              label={t("transaction.isSubscription")}
                              text={
                                isSubscription === true ? (
                                  <Tag color="positive">{t("common.true")}</Tag>
                                ) : (
                                  <Tag color="gray">{t("common.false")}</Tag>
                                )
                              }
                            />
                          ))
                          .otherwise(() => null)}


                        {match(enrichedTransactionInfo)
                          .with({ carbonFootprint: P.select(P.string) }, carbonFootprint => (
                            <DetailLine
                              label={t("transaction.carbonFootprint")}
                              text={t("transaction.carbonFootprint.value", {
                                carbonFootprint: Number(carbonFootprint) / 1_000_000,
                              })}
                            />
                          ))
                          .otherwise(() => null)}

                        {match(enrichedTransactionInfo)
                          .with({ enrichedMerchantName: P.string }, () => (
                            <DetailLine label={t("transaction.label")} text={transaction.label} />
                          ))
                          .otherwise(() => null)}
                  </ReadOnlyFieldList>
                );
              },
            )
            .with(
              { __typename: "SEPACreditTransferTransaction" },
              ({ createdAt, debtor, creditor }) => {
                const debtorIban = debtor.IBAN;
                const creditorIban = creditor.IBAN;

                return (
                  <ReadOnlyFieldList>
                    <Line
                      label={t("transaction.paymentDateTime")}
                      text={formatDateTime(new Date(createdAt), "LLL")}
                      icon="calendar-ltr-regular"
                    />

                    {isNotNullish(accountId) && category}

                    <Line
                      label={t("transaction.debtorName")}
                      text={debtor.name}
                      icon="person-regular"
                    />

                    {isNotNullish(debtorIban) && (
                      <CopiableLine
                        label={t("transaction.debtorIban")}
                        text={printIbanFormat(debtorIban)}
                      />
                    )}

                    <Line
                      label={t("transaction.creditorName")}
                      text={creditor.name}
                      icon="person-regular"
                    />

                    {isNotNullish(creditorIban) && (
                      <CopiableLine
                        label={t("transaction.creditorIban")}
                        text={printIbanFormat(creditorIban)}
                      />
                    )}

                    <Line
                      label={t("transaction.paymentMethod")}
                      text={t("transactions.method.Transfer")}
                      icon="arrow-swap-regular"
                    />
                  </ReadOnlyFieldList>
                );
              },
            )
            .with(
              { __typename: "SEPADirectDebitTransaction" },
              ({
                mandate,
                creditor,
                debtor,
                reservedAmount,
                reservedAmountReleasedAt,
                createdAt,
              }) => {
                const ultimateCreditorName = match(mandate)
                  .with(
                    {
                      __typename: "SEPAReceivedDirectDebitMandate",
                      ultimateCreditorName: P.select(P.string),
                    },
                    ultimateCreditorName => ultimateCreditorName,
                  )
                  .otherwise(() => null);

                return (
                  <ReadOnlyFieldList>
                    <Line
                      label={t("transaction.paymentDateTime")}
                      text={formatDateTime(new Date(createdAt), "LLL")}
                      icon="calendar-ltr-regular"
                    />

                    {isNotNullish(accountId) && category}

                    <Line
                      label={t("transaction.debtorName")}
                      text={debtor.name}
                      icon="person-regular"
                    />

                    {isNotNullish(debtor.IBAN) && (
                      <CopiableLine
                        label={t("transaction.debtorIban")}
                        text={printIbanFormat(debtor.IBAN)}
                      />
                    )}

                    <Line
                      label={t("transaction.creditorName")}
                      text={
                        isNotNullishOrEmpty(ultimateCreditorName)
                          ? ultimateCreditorName
                          : creditor.name
                      }
                      icon="person-regular"
                    />

                    {isNotNullish(creditor.IBAN) && (
                      <CopiableLine
                        label={t("transaction.creditorIban")}
                        text={printIbanFormat(creditor.IBAN)}
                      />
                    )}

                    {isNotNullish(reservedAmount) && (
                      <Line
                        label={t("transaction.reservedAmount")}
                        text={formatCurrency(Number(reservedAmount.value), reservedAmount.currency)}
                      />
                    )}

                    {isNotNullish(reservedAmountReleasedAt) && (
                      <Line
                        label={t("transaction.reservedUntil")}
                        text={formatDateTime(new Date(reservedAmountReleasedAt), "LLL")}
                        icon="calendar-ltr-regular"
                      />
                    )}

                    <Line
                      label={t("transaction.paymentMethod")}
                      text={t("transactions.method.DirectDebit")}
                      icon="arrow-swap-regular"
                    />
                  </ReadOnlyFieldList>
                );
              },
            )
            .with(
              { __typename: "InternalDirectDebitTransaction" },
              ({ creditor, reservedAmount, reservedAmountReleasedAt, createdAt }) => (
                <ReadOnlyFieldList>
                  <Line
                    label={t("transaction.paymentDateTime")}
                    text={formatDateTime(new Date(createdAt), "LLL")}
                    icon="calendar-ltr-regular"
                  />

                  {isNotNullish(accountId) && category}

                  {isNotNullish(reservedAmount) && (
                    <Line
                      label={t("transaction.reservedAmount")}
                      text={formatCurrency(Number(reservedAmount.value), reservedAmount.currency)}
                    />
                  )}

                  {isNotNullish(reservedAmountReleasedAt) && (
                    <Line
                      label={t("transaction.reservedUntil")}
                      text={formatDateTime(new Date(reservedAmountReleasedAt), "LLL")}
                      icon="calendar-ltr-regular"
                    />
                  )}

                  <Line label={t("transaction.creditorName")} text={creditor.accountId} />

                  <Line
                    label={t("transaction.paymentMethod")}
                    text={t("transactions.method.DirectDebit")}
                    icon="arrow-swap-regular"
                  />
                </ReadOnlyFieldList>
              ),
            )
            .with({ __typename: "InternalCreditTransfer" }, ({ creditor, createdAt }) => (
              <ReadOnlyFieldList>
                <Line
                  label={t("transaction.paymentDateTime")}
                  text={formatDateTime(new Date(createdAt), "LLL")}
                  icon="calendar-ltr-regular"
                />

                <Line
                  label={t("transaction.creditorName")}
                  text={creditor.name}
                  icon="person-regular"
                />

                <Line
                  label={t("transaction.paymentMethod")}
                  text={t("transactions.method.Transfer")}
                  icon="arrow-swap-regular"
                />
              </ReadOnlyFieldList>
            ))
            .with(
              { __typename: "FeeTransaction" },
              ({ counterparty, feesType, originTransaction, createdAt }) => (
                <ReadOnlyFieldList>
                  <Line
                    label={t("transaction.paymentDateTime")}
                    text={formatDateTime(new Date(createdAt), "LLL")}
                    icon="calendar-ltr-regular"
                  />

                  {isNotNullish(accountId) && category}

                  <Line
                    label={t("transaction.creditorName")}
                    text={counterparty}
                    icon="person-regular"
                  />

                  {originTransaction != null && (
                    <ReadOnlyFieldList>
                      <LakeLabel
                        type="viewSmall"
                        label={t("transaction.relatedTransaction")}
                        actions={
                          <Link
                            to={Router.AccountTransactionsListDetail({
                              accountMembershipId,
                              transactionId: originTransaction.id,
                            })}
                          >
                            <Icon size={20} name="arrow-right-regular" color={colors.swan[900]} />
                          </Link>
                        }
                        render={() => (
                          <Box direction="row" alignItems="center">
                            <Icon name="calendar-ltr-regular" size={16} />
                            <Space width={8} />

                            <LakeText variant="regular" color={colors.gray[900]}>
                              {formatDateTime(new Date(originTransaction.executionDate), "LLL")}
                            </LakeText>
                          </Box>
                        )}
                      />

                      {match(feesType)
                        .with("CashWithdrawalsOutsideSEPA", "CardPaymentsOutsideSEPA", () => (
                          <Line
                            label={t("transaction.originalTransactionAmount")}
                            text={formatCurrency(
                              Number(originTransaction.amount.value),
                              originTransaction.amount.currency,
                            )}
                          />
                        ))
                        .with("DirectDebitRejection", () => (
                          <Line
                            label={t("transaction.rejectedAmount")}
                            text={formatCurrency(
                              Number(originTransaction.amount.value),
                              originTransaction.amount.currency,
                            )}
                          />
                        ))
                        .otherwise(() => null)}
                    </ReadOnlyFieldList>
                  )}

                  <Line
                    label={t("transaction.paymentMethod")}
                    text={t("transactions.method.Fees")}
                    icon="arrow-swap-regular"
                  />
                </ReadOnlyFieldList>
              ),
            )
            .with(
              { __typename: "CheckTransaction" },
              ({ cmc7, createdAt, reservedAmount, reservedAmountReleasedAt, rlmcKey }) => {
                // The check number is the first 7 numbers of the cmc7
                const checkNumber = cmc7.slice(0, 7);

                return (
                  <ReadOnlyFieldList>
                    <Line
                      label={t("transaction.paymentDateTime")}
                      text={formatDateTime(new Date(createdAt), "LLL")}
                      icon="calendar-ltr-regular"
                    />

                    {isNotNullish(accountId) && category}

                    {isNotNullish(reservedAmount) && (
                      <Line
                        label={t("transaction.reservedAmount")}
                        text={formatCurrency(Number(reservedAmount.value), reservedAmount.currency)}
                      />
                    )}

                    {isNotNullish(reservedAmountReleasedAt) && (
                      <Line
                        label={t("transaction.reservedUntil")}
                        text={formatDateTime(new Date(reservedAmountReleasedAt), "LLL")}
                        icon="calendar-ltr-regular"
                      />
                    )}

                    <CopiableLine label={t("transaction.cmc7")} text={cmc7} />
                    <CopiableLine label={t("transaction.rlmcKey")} text={rlmcKey} />
                    <CopiableLine label={t("transaction.checkNumber")} text={checkNumber} />

                    <Line
                      label={t("transaction.paymentMethod")}
                      text={t("transactions.method.Check")}
                      icon="arrow-swap-regular"
                    />
                  </ReadOnlyFieldList>
                );
              },
            )
            .with(
              { __typename: "InternationalCreditTransferTransaction" },
              ({ createdAt, creditor, internationalCurrencyExchange }) => (
                <ReadOnlyFieldList>
                  <Line
                    label={t("transaction.paymentDateTime")}
                    text={formatDateTime(new Date(createdAt), "LLL")}
                    icon="calendar-ltr-regular"
                  />

                  {isNotNullish(accountId) && category}

                  {match(creditor)
                    .with(
                      { __typename: "InternationalCreditTransferOutCreditor" },
                      ({ name, details }) => (
                        <ReadOnlyFieldList>
                          <Line
                            label={t("transaction.creditorName")}
                            text={name}
                            icon="person-regular"
                          />

                          {details.map(detail => (
                            <Line
                              key={getWiseIctLabel(detail.key)}
                              label={getWiseIctLabel(detail.key)}
                              text={getWiseIctLabel(detail.value)}
                            />
                          ))}
                        </ReadOnlyFieldList>
                      ),
                    )
                    .otherwise(() => null)}

                  <Line
                    label={t("transactionDetail.internationalCreditTransfer.exchangeRate")}
                    text={internationalCurrencyExchange.exchangeRate}
                  />

                  <Line
                    label={t("transaction.paymentMethod")}
                    text={t("transactions.method.Transfer")}
                    icon="arrow-swap-regular"
                  />
                </ReadOnlyFieldList>
              ),
            )

            .otherwise(() => null)}

          <Separator space={8} />

          {/* common fields */}
          <ReadOnlyFieldList>
            <CopiableLine
              label={t("transaction.reference")}
              text={isNotEmpty(transaction.reference) ? transaction.reference : "—"}
            />

            <CopiableLine
              label={t("transaction.id")}
              text={truncateTransactionId(transaction.id)}
            />

            {match(transaction)
            .with({__typename: "SEPACreditTransferTransaction"}, () => repeatTransaction)
            .otherwise(() => null)
            }

          </ReadOnlyFieldList>
        </ScrollView>
      </ListRightPanelContent>
    </ScrollView>}
    </>
  );
};
